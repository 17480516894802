var translations = {
    pl: {
        list: "Obiekty",
        people: "Osoby",
        bibliography: "Bibliografia",
        search: "Szukaj",
        historicAddresses: "Adresy historyczne",
        constructionYear: "Rok budowy",
        architect: "Architekt",
        investor: "Inwestor",
        searchResult: "Wyniki wyszukiwania",
        cityMap: "Plan miasta",
        currentAddress: "Adres współczesny",
        owners: "Właściciele",
        description: "Opis",
        additionalInformation: "Dodatkowe informacje",
        earlierLaterBuildings: "Wcześniejsze/późniejsze zabudowania",
        reconstructions: "Przebudowy/instalacje",
        shops: "Geszefty",
        citizens: "Znani mieszkańcy",
        archives: "Bibliografia, archiwalia, odnośniki",
        name: "Nazwa",
        libraryList: "Biblioteka Śląska",
        visitPage: "Odwiedź stronę",
        fullObjectDocumetation: "Zobacz pełną dokumentację obiektu",
        notExists: "(nie istnieje)",
        aboutProject: "O projekcie",
        education: "Edukacja"
    },
    de: {
        list: "Objekte",
        people: "Personen",
        bibliography: "Bibliographie",
        search: "Suchen",
        historicAddresses: "Historische Adressen",
        constructionYear: "Baujahr",
        architect: "Architekt",
        investor: "Bauherr",
        searchResult: "Die Ergebnisse der Suche",
        cityMap: "Stadtplan",
        currentAddress: "Heutige Adresse",
        owners: "Eigentümer",
        description: "Beschreibung",
        additionalInformation: "Sonstiges",
        earlierLaterBuildings: "Frühere/Spätere Bebauung",
        reconstructions: "Umbauten/Anlagen",
        shops: "Geschäfte",
        citizens: "Mieter, falls bekannt",
        archives: "Literatur, Verweise, Links",
        name: "Name",
        libraryList: "Biblioteka Śląska",
        visitPage: "Gehen Sie zur Website",
        fullObjectDocumetation: "Siehe vollständige Dokumentation",
        notExists: "(existiert nicht mehr)",
        aboutProject: "Projekt",
        education: "Buildung"
    }
}

var language = "pl";
if (localStorage.language) {
    language = localStorage.getItem('language');
}

const localization = {
    l: language,
    changeLang: (lang) => {
        localization.dictionary = translations[lang];
        localStorage.setItem('language', lang);
        localization.l = lang;

    },
    getLang: function () {
        return localization.l;
    },
    dictionary: translations[language],
}

localization.install = function (Vue) {
    Vue.observable(localization);
    Vue.prototype.$localization = localization;
    Vue.prototype.$t = (word) => localization.dictionary[word];
    Vue.prototype.$lang = () => localization.l;
}

export default localization;