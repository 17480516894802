<template>
  <header class="main-logo">
    <div class="logo-filter">
      <img src="/images/main/logo.png" alt="" class="img-fluid" />
    </div>
  </header>
</template>
<script>
export default {
  name: "Header",
  data() {
    return {};
  },
};
</script>
<style>
.main-logo {
  background-image: url("/images/main/main.jpg");
}
.logo-filter {
  background: rgba(156, 14, 22, 0.7);
}
</style>