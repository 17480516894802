//#region constants
var mainUrl = 'http://77.55.217.151/api';
const devUrl = 'https://localhost:44374'
const isDev = false;
if (isDev) {
    mainUrl = devUrl;
}

const urls = {
    bibliography: mainUrl + '/bibliography',
    people: mainUrl + '/people',
    buildings: mainUrl + '/buildings',
    search: mainUrl + '/search',
    aboutProject: mainUrl + '/about-project',
    education: mainUrl + '/education/'
}

const store = {

}

async function getData(url) {
    let response = await fetch(url);
    if (!response.ok) {
        response = await fetch(url);
    }
    return await response.json();
}
//#endregion

//#region api
const api = {
    getBibliography: async function () {
        if (!store.bibliography) {
            store.bibliography = await getData(urls.bibliography);
        }
        return store.bibliography
    },
    getPeople: async function () {
        if (!store.people) {
            store.people = await getData(urls.people);
        }
        return store.people;
    },
    getPerson: async function (id) {
        return await getData(urls.people + '/' + id);
    },
    getBuildings: async function () {
        if (!store.buildings || store.buildings.length == 0) {
            store.buildings = await getData(urls.buildings);
        }
        return store.buildings;
    },
    getBuilding: async function (slug) {
        return await getData(urls.buildings + '/' + slug);
    },
    search: async function (query) {
        return await getData(urls.search + '?query=' + query);
    },
    getAboutProject: async function () {
        return await getData(urls.aboutProject);
    },
    getEducationMaterials: async function () {
        return await getData(urls.education);
    },
}

//#endregion

api.install = function (Vue) {
    Vue.prototype.$api = api;
}

export default api;