<template>
  <div class="container-fluid mt-4">
    <div class="row">
      <div class="col-md-4">
        <img
        class="card-img-top"
        :src="getPath(aboutProject.image)"
        alt="Card image cap"
      />
      </div>
      <div class="col-md-8">
        <h1 class="mb-2">{{ $t("aboutProject") }}</h1>
        <div v-html="aboutProject.contentPL" v-if="$lang() == 'pl'"></div>
        <div v-html="aboutProject.contentDE" v-if="$lang() == 'de'"></div>
      </div>
 
    </div>
  </div>
</template>
<script>
export default {
  name: "AboutProject",
  data() {
    return {
      aboutProject: {},
      imagePath: "",
    };
  },
  methods: {
    getPath: function (file) {
      return this.imagePath + file;
    },
  },
  created() {
    this.$api.getAboutProject().then((data) => {
      this.aboutProject = data;
      this.imagePath = this.$settings.galleryPath;
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.$api.getAboutProject().then((data) => {
      this.aboutProject = data;
      next();
    });
  },
};
</script>
<style >
a {
  word-wrap: break-word;
}
</style>