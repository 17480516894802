import Vue from 'vue'
import App from './App.vue'
import router from './router'
import api from './api';
import localization from './localization';
import settings from './settings';
import VueImg from 'v-img';

Vue.config.productionTip = false

Vue.use(api)

Vue.use(localization)

Vue.use(settings)

Vue.use(VueImg)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

