import Vue from 'vue'
import VueRouter from 'vue-router'
import AboutProject from '../components/AboutProject.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'AboutProject',
    component: AboutProject
  },
  {
    path: '/mapa',
    name: 'CityMap',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/CityMap.vue')
  },
  {
    path: '/osoby',
    name: 'People',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/People.vue')
  },
  {
    path: '/bibliografia',
    name: 'Bibliography',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/Bibliography.vue')
  },
  {
    path: '/budynek/:buildingId',
    name: 'Building',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/Building.vue')
  },
  {
    path: '/osoba/:personId',
    name: 'Person',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/Person.vue')
  },
  {
    path: '/szukaj/:query',
    name: 'Search',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/Search.vue')
  },
  {
    path: '/zdjecie/:slug/:file/:ext',
    name: 'Picture',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/Picture.vue')
  },
  {
    path: '/obiekty',
    name: 'List',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/List.vue')
  },
  {
    path: '/edukacja',
    name: 'Education',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/Education.vue')
  },
  {
    path :'*',
    component:AboutProject
}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
