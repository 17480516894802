<template>
  <nav class="navbar sticky-top navbar-expand-lg navbar-dark bg-dark">
    <!-- <a class="navbar-brand" href="#">Navbar</a> -->
    <button
      class="navbar-toggler collapsed"
      type="button"
      data-toggle="collapse"
      data-target="#navbarColor01"
      aria-controls="navbarColor01"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="navbar-collapse collapse" id="navbarColor01" style="">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <router-link :to="{ name: 'AboutProject' }" class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show">{{
            $t("aboutProject")
          }}</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'List' }" class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show">{{
            $t("list")
          }}</router-link>
        </li>
                <li class="nav-item">
          <router-link :to="{ name: 'People' }" class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show">{{
            $t("people")
          }}</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'CityMap' }" class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" >{{
            $t("cityMap")
          }}</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'Bibliography' }" class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show">{{
            $t("bibliography")
          }}</router-link>
        </li>
          <!-- <li v-if="$lang() == 'pl'" class="nav-item">
          <router-link :to="{ name: 'Education' }" class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show">{{
            $t("education")
          }}</router-link>
        </li> -->
      </ul>
      <div class="language-dropdown my-2 my-md-0">
        <form class="form-inline">
          <select
            name="language"
            class="form-control mr-sm-2"
            @change="changeLanguage()"
            v-model="language"
          >
            <option value="pl">PL</option>
            <option value="de">DE</option>
          </select>
        </form>
      </div>
    </div>

    <form class="search-form my-2 my-md-0" v-on:submit.prevent="search()" style="">
      <input
        class="form-control mr-sm-2"
        type="search"
        :placeholder="$t('search')"
        aria-label="Search"
        v-model="query"

      />
      <button class="btn btn-outline-info  my-md-0 ml-2" type="submit">
        <i class="fa fa-search"></i>
      </button>
    </form>
  </nav>
</template>
<script>
export default {
  data() {
    return {
      language: "pl",
      query: "",
      previousQuery: "",
      error: "",
    };
  },
  methods: {
    changeLanguage() {
      this.$localization.changeLang(this.language);
    },
    search() {
      if (!this.query) {
        return;
      }
      if (this.query == this.previousQuery && this.$route.name == "Search") {
        return;

    }

      this.previousQuery = this.query;
      this.$router
        .push({ name: "Search", params: { query: this.query } })
        .catch((err) => {
          this.error = err;
        });
    },
  },
  created(){
   this.language =  this.$localization.getLang();
  }

};
</script>
<style>
.nav-item {
  font-size: 23px;
}
.form-control {
  font-size: 20px;
}
.btn {
  font-size: 20px;
}
.language-dropdown {
  max-width: 80px;
}
.search-form{
  display: flex;
  
}
</style>