const settings = {
    galleryPath: "/pictures/",
    cardPath:"/images/cards/",
    downloadPath:"/downloads/"
}

settings.install = function (Vue) {
    Vue.prototype.$settings = settings;
}

export default settings;