<template>
  <div id="app">
    <Header v-if="$route.name != 'Picture'" />
    <Navbar v-if="$route.name != 'Picture'" />
    <div class="rout-container">
      <router-view />
    </div>
    <Footer v-if="$route.name != 'Picture'" />
  </div>
</template>

<script>
import Header from "./components/Header";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
export default {
  components: {
    Header,
    Navbar,
    Footer,
  },
  data() {
    return {};
  },
};
</script>
<style>
:root {
  --primary-color: #9c0e16;
  --black: #212529;
}

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: Arquitecta Medium;
  font-size: 22px;
  background-color: #dcdcdc;
}
a {
  overflow-wrap: break-word;
}
.rout-container {
  flex-grow: 1;
}
@font-face {font-family: "Arquitecta Medium";
   src: url("/font/40fbe6a81ff9ec94593d0436835fa47f.ttf")
}
</style>
